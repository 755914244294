import React, { useState } from 'react'

import {
	MapPinIcon,
	InformationCircleIcon,
	MapIcon,
	DocumentDuplicateIcon,
	ArrowTrendingUpIcon,
	QueueListIcon,
	SparklesIcon,
} from '@heroicons/react/20/solid'

import ViewOnMap from './ViewOnMap'
import ViewSimilarLoads from './ViewSimilarLoads'
import LoadPriceChart from './LoadPriceChart'

import './loadcollapse.css'

function LoadCollapse({ load, isDarkMode, styles, isSimilarLoads }) {
	const [isCopied, setIsCopied] = useState(false)
	const [isMapModalOpen, setMapModalOpen] = useState(false)
	const [isSimilarLoadsModalOpen, setSimilarLoadsModalOpen] = useState(false)

	const { broker, comments } = load

	const viewSimilarLoadsClick = event => {
		event.stopPropagation()
		setSimilarLoadsModalOpen(true)
	}

	const closeSimilarLoadsModal = () => {
		setSimilarLoadsModalOpen(false)
	}

	const viewOnMapClick = event => {
		event.stopPropagation()
		setMapModalOpen(true)
	}

	const closeMapModal = () => {
		setMapModalOpen(false)
	}

	const copyHandler = event => {
		event.stopPropagation()

		const textToCopy = `Company: ${broker.company}\nCompany Name: ${broker.companyName}\nPhone: ${broker.phone}`

		const textarea = document.createElement('textarea')
		textarea.value = textToCopy
		document.body.appendChild(textarea)
		textarea.select()

		if (document.execCommand('copy')) {
			setIsCopied(true)
		} else {
			console.error('Failed to copy text')
		}
		document.body.removeChild(textarea)
	}

	return (
		<div
			className="max-w-screen-xl mx-auto col-span-12 transition-all border-solid border px-2 py-3 rounded-md"
			style={{ ...styles, height: 'auto', overflow: 'hidden' }}
		>
			{/* Horizontal Divider */}
			<div className="col-span-12 h-px my-2"></div>

			<ViewOnMap
				stops={load?.loads}
				isModalOpen={isMapModalOpen}
				closeModal={closeMapModal}
			/>

			{/* Collapsible content goes here */}
			<div className="grid grid-cols-12 gap-4">
				{/* Column 1: Load Stops */}
				<div
					className={`col-span-3 ${
						isDarkMode ? 'loadcollapse-dark' : 'loadcollapse-light'
					} p-4 rounded`}
				>
					<div className="flex items-center mb-2">
						<MapPinIcon className="h-5 w-5 mr-1" aria-hidden="true" />
						<h3 className="text-lg font-semibold">Load Stops</h3>{' '}
						{/* Add AI Bookable tag */}
						{load.isAIEnabled && (
							<div className="flex items-center ml-3">
								<SparklesIcon
									className="h-4 w-4 mr-1 text-purple-400"
									aria-hidden="true"
								/>
								<span className="text-sm font-semibold text-purple-400">
									AI Bookable
								</span>
							</div>
						)}
					</div>

					{!isSimilarLoads && (
						<button
							onClick={viewOnMapClick}
							className="flex items-center bg-cyan-500 text-white px-3 py-1 rounded mb-4"
						>
							<MapIcon className="h-5 w-5" aria-hidden="true" />
							<span className="ml-2">View On Map</span>
						</button>
					)}

					{load?.loads?.map((stop, index) => (
						<div className="flex items-center mb-2" key={index}>
							<span
								className={`${
									isDarkMode ? 'text-gray-200' : 'text-gray-700'
								} font-bold mr-2`}
							>
								{index + 1}.
							</span>
							<p className="text-sm">
								{stop?.label && stop?.label} {stop?.city}, {stop?.state}
								<span className="ml-2 text-green-500">
									{stop?.isBobtail ? 'Bobtail' : ''}
								</span>
							</p>
						</div>
					))}
				</div>

				{/* Column 2: Broker Info */}
				<div
					className={`col-span-3 ${
						isDarkMode ? 'loadcollapse-dark' : 'loadcollapse-light'
					} p-4 rounded`}
				>
					<div className="flex items-center mb-2">
						<InformationCircleIcon
							className="h-5 w-5 mr-1"
							aria-hidden="true"
						/>
						<h3 className="text-lg font-semibold">Broker Info</h3>
					</div>

					{!isSimilarLoads && (
						<button
							onClick={copyHandler}
							className="flex items-center bg-cyan-500 text-white px-3 py-1 rounded mb-4"
						>
							<DocumentDuplicateIcon className="h-4 w-4" aria-hidden="true" />
							<span className="ml-2">{isCopied ? 'Copied' : 'Copy Info'}</span>
						</button>
					)}

					<p className="text-sm mb-2">
						Company Name: {broker?.companyName || broker.company}
					</p>
					<p className="text-sm mb-2">Contact: {broker.phone}</p>
					<p className="text-sm mb-2">Comment: {comments}</p>
				</div>

				{/* Column 3: Analytics */}
				<div
					className={`col-span-6 ${
						isDarkMode ? 'loadcollapse-dark' : 'loadcollapse-light'
					} p-4 rounded`}
				>
					<div className="flex items-center mb-2">
						<ArrowTrendingUpIcon className="h-5 w-5 mr-1" aria-hidden="true" />
						<h3 className="text-lg font-semibold">Analytics</h3>
					</div>

					{!isSimilarLoads && (
						<>
							<ViewSimilarLoads
								isModalOpen={isSimilarLoadsModalOpen}
								closeModal={closeSimilarLoadsModal}
								load={load}
							/>

							<button
								onClick={viewSimilarLoadsClick}
								className="flex items-center bg-cyan-500 text-white px-3 py-1 rounded mb-4"
							>
								<QueueListIcon className="h-4 w-4" aria-hidden="true" />
								<span className="ml-2">View Similar Loads</span>
							</button>
						</>
					)}

					<LoadPriceChart isDarkMode={isDarkMode} load={load} />
				</div>
			</div>
		</div>
	)
}

export default LoadCollapse
